/* footer */
footer {
	background-color: #000;
	color: #fff;
	padding-top: 40px;
	font-size: 15px;
	.container {
		width: 100%;
		padding: 0 70px;
		text-align: center;
		position: relative;
	}
	a {
		color: #fff;
		font-size: 15px;
		&:hover {
			text-decoration: none;
		}
	}
	.footer__logo {
		display: table;
		margin: 0 auto;
	}
	.footer--nav {
		display: table;
		margin: 15px auto;
		ul {
			@include ul-default;
			li {
				position: relative;
				@include inline-block;
				&:after {
					content: '';
					display: block;
					height: 20px;
					width: 3px;
					background-color: #fff;
					position: absolute;
					top: 0;
					right: -1px;
				}
				&:last-of-type:after {
					display: none;
				}
				a {
					display: block;
					font-family: $bold;
					font-size: 20px;
					color: #fff;
					text-transform: uppercase;
					line-height: 1;
					padding: 0 22px;
					&:hover {
						color: #299242;
						text-decoration: none;
					}
				}
				&.active a {
					color: #299242;
				}
			}
		}
	}
	.footer__col {
		width: 250px;
		position: absolute;
		top: 100px;
		ul {
			@include ul-default;
			li {
				display: block;
				span {
					font-family: $bold;	
					text-transform: uppercase;
				}
			}
		}
		.footer__col__left {
			font-family: $bold;
			text-transform: uppercase;
			@include inline-block;
		}
		.footer__col__right {
			width: calc(100% - 62px);
			@include inline-block;
		}
		&.left {
			width: 320px;
			left: 70px;
			text-align: left;
		}
		&.right {
			right: 70px;
			text-align: left;
			ul li span {
				font-family: $bold;
			}
			.footer__col__right {
				width: calc(100% - 90px);
				font-family: $regular;	
				a {
					display: block;
				}
			}
		}
	}
	.socials {
		display: table;
		padding: 20px 60px;
		margin: 20px auto 0;
		border-top: 1px solid #299242;
		ul {
			@include ul-default;
			li {
				margin: 0 8px;
				@include inline-block;
				a {
					font-size: 18px;
					color: #dcdcdc;
					&:hover {
						color: #299242;
					}
				}
			}
		}
	}
	.container-fluid {
		background-color: #fff;
		color: #3b3a3a;
		padding: 10px 0;
		margin-top: 25px;
		.container {
			text-align: left;
		}
		p {
			margin: 0;
			font-size: 13px;
			@include inline-block;
		}
		.artmedia {
			float: right;
			width: 235px;
			font-size: 13px;
			color: #3b3a3a;
			padding-right: 120px;
			box-sizing: border-box;
			position: relative;
			@include inline-block;
			img {
				position: absolute;
				right: 0;
				top: -5px;
			}
		}
	}
	@media screen and (max-width: 1680px) {
		.container {
			padding: 0 25px;
		}
		.footer__col {
			&.left {
				left: 25px;
			}
			&.right {
				right: 25px;
			}
		}
	}
	@media screen and (max-width: 1440px) {
		.footer--nav ul li a {
			padding: 0 12px;
			font-size: 16px;
		}
	}
	@media screen and (max-width: 1280px) {
		.footer--nav {
			ul li {
				&:after {
					height: 15px;
				}
				a {
					font-size: 16px;
				}
			}
		}
		.footer__col {
			display: table;
			position: relative;
			top: 0;
			margin: 0 auto;
			&.right, &.left {
				right: auto;
				left: auto;
			}
			&.left {
				width: auto;
				white-space: nowrap;
			}
			span {
				margin-bottom: 10px;
			}
			ul li {
				margin-bottom: 10px;
			}
		}	
	}
	@media screen and (max-width: 768px) {
		padding-top: 15px;
		.footer--nav {
			display: none;
		}
		.footer__col {
			&.left {
				margin-top: 10px;
			}
		}
		.socials {
			padding: 10px 40px;
		}
		.container {
			padding: 0 15px;
		}
		.container-fluid {
			margin-top: 15px;
			.container {
				padding: 0 15px;
			}
		}
	}
	@media screen and (max-width: 580px) {
		.footer__col {
			&.left {
				white-space: inherit;
			}
		}
	}
	@media screen and (max-width: 480px) {
		.container-fluid {
			p {
				display: block;
				text-align: center;
			}
			.artmedia {
				display: table;
				margin: 10px auto;
				text-align: left;
				float: none;
			}
		}
	}
}
/* footer */