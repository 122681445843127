/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main banner */
.banner {
	position: relative;

	/* main banner slider nav */
	.banner__slider--nav {
		margin-bottom: 0!important;
		position: relative;
		.slick-slide {
			position: relative;
			outline: none;
			.banner__slider--nav__block {
				width: 1170px;
				position: absolute;
				left: 0;
				right: 0;
				top: 50%;
				margin: auto;
				margin-top: -140px;
				text-align: center;
				color: #fff;
				z-index: 1;
				.banner__slider--nav__block__title {
					font-family: $bold;
					font-size: 45px;
					color: #fff;
					display: block;
					text-transform: uppercase;
					line-height: 1.2;
				}
				.btn {
					font-size: 14px;
					text-transform: uppercase;
					padding: 16px 70px!important;
					margin: 125px auto 0;
					border: 2px solid #ffffff;
					background-color: transparent!important;
					color: #fff!important;
					outline: none;
					&:hover {
						background-color: #fff!important;
						color: #299242!important;
					}
				}
			}
			> img {
				visibility: hidden;
				display: block;
				width: 100%;
			}
		}
		.slick-dots {
			bottom: 165px;
			li {
				width: 20px;
				height: 20px;
				button {
					background-color: #efefef;
					width: 100%;
					height: 100%;
					border: 2px solid #fff;
					border-radius: 0px;
					background-color: transparent;
					transition: 300ms;
					&:before {
						display: none;
					}
					&:hover {
						background-color: #ffffff;
					}
				}
				&.slick-active {
					button {
						background-color: #ffffff;
					}
				}
			}
		}
	}
	/* main banner slider nav */

	/* main banner slider for */
	.banner__slider--for {
		display: block;
		width: 100%;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		margin-bottom: 0px!important;
		z-index: -1;
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.1);
			background-image: url(../img/bg.png);
			background-repeat: repeat;
			background-position: top left;
			z-index: 1;
		}
		&:before {
			content: '';
			height: 200px;
			position: absolute;	
			top: 0;
			left: 0;
			right: 0;
	        background: linear-gradient(to bottom, rgba(0,0,0,.75), rgba(0,0,0,0));
			z-index: 1;
		}
		.slick-slide {
			overflow: hidden;
			position: relative;
			outline: none;
			img {
				width: 100%;
				height: auto;
				display: block;
			}
		}
	}
	/* main banner slider nav */
		
	.banner__btn--down {
		display: block;
		width: 28px;
		height: 58px;
		background: url(../img/arrow-down.png) no-repeat center center;
		background-size: contain;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 30px;
		margin: 0 auto;
		transition: 500ms;
		&:hover {
			transform: scale(.9);
		}
	}

	/* page banner */
	.banner__img {
		display: block;
		width: 100%;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		z-index: -1;
		img {
			display: block;
			width: 100%;
		}
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.1);
			background-image: url(../img/bg.png);
			background-repeat: repeat;
			background-position: top left;
		}
		&:before {
			content: '';
			height: 200px;
			position: absolute;	
			top: 0;
			left: 0;
			right: 0;
	        background: linear-gradient(to bottom, rgba(0,0,0,.75), rgba(0,0,0,0));
			z-index: 1;
		}
	}

	.banner__block {
		position: relative;
		overflow: hidden;
		img {
			visibility: hidden;
			display: block;
			width: 100%;
		}
		.banner__block__title {
			display: block;
			min-width: 600px;
			position: absolute;
			bottom: 70px;
			left: 15%;
			padding: 80px 45px;
			background-color: rgba(0,0,0,0.3);
			line-height: 1;
			box-sizing: border-box;
			h1 {
				font-family: $bold;
				margin: 0;
				color: #fff;
				text-align: left;
			}
		}
	}
	/* page banner */

	@media screen and (max-width: 1440px) {
		.banner__slider--nav {
			.slick-dots {
				bottom: 120px;
			}
		}
		.banner__btn--down {
			bottom: 20px;
		}
	}
	@media screen and (max-width: 1280px) {
		.banner__slider--nav {
			.slick-slide {
				.banner__slider--nav__block {
					width: 100%;
					padding: 0 20px;
					top: auto;
					bottom: 160px;
					box-sizing: border-box;
					.banner__slider--nav__block__title {
						font-size: 40px;
					}
					
				}
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.banner__slider--for, .banner__img {
			margin-top: 85px;
		}
	}
	@media screen and (max-width: 991px) {
		.banner__slider--nav {
			.slick-slide {
				.banner__slider--nav__block {
					bottom: 100px;
					.banner__slider--nav__block__title {
						font-size: 35px;
					}
					.btn {
						padding: 8px 15px!important;
						margin: 25px auto 0 auto;
					}
				}
			}
			.slick-dots {
				bottom: 15px;
				li {
					width: 15px;
					height: 15px;
				}
			}
		}
		.banner__btn--down {
			display: none;
		}
		.banner__block {
			.banner__block__title {
				padding: 50px 25px;
				bottom: 25px;
				left: 15px;
			}
		}
		h1 {
			font-size: 28px;
		}
	}
	@media screen and (max-width: 768px) {
		&:after {
			height: 120px;
		}
		.banner__slider--nav {
			.slick-slide {
				.banner__slider--nav__block {
					bottom: 75px;
					.banner__slider--nav__block__title {
						font-size: 25px;
					}
				}
			}
		}
		.banner__block {
			.banner__block__title {
				min-width: auto; 
				padding: 25px 20px;
				bottom: 15px;
			}
		}
		h1 {
			font-size: 22px;
		}
	}
	@media screen and (max-width: 620px) {
		&:after {
			height: 75px;
		}
		.banner__slider--nav {
			.slick-slide img {
				height: 295px;
				width: auto;
			}
		}
		.banner__slider--for {
			.slick-slide img {
				height: 295px;
				width: auto;
			}
		}
	}
	@media screen and (max-width: 480px) { 
		.banner__slider--nav {
			.slick-slide {
				.banner__slider--nav__block {
					.banner__slider--nav__block__title {
						font-size: 22px;
					}
				}
			}
		}
		.banner__slider--for {
			.slick-slide img {
				margin-left: -80px;
			}
		}
		h1 {
			font-size: 20px;
		}
	}
}
/* main banner */

/* floating  block */
.floating__block {
	position: relative;
	overflow: hidden;
	padding: 70px 0 0 0;
	.floating__block__col {
		width: 460px;
		position: absolute;
		top: 132px;
		padding: 0px 0 40px;
		box-sizing: border-box;
		@include inline-block;
		.floating__block__col__title {
			display: block;
			margin: 0px -30px 25px -30px;
			box-sizing: border-box;
			padding: 40px 35px;
			background-color: #00529f;
			font-size: 18px;
			color: #fff;
			&.blue {
				background-color: rgba(0, 69, 134, 0.9);
			}
			&.blue-green {
				background-color: rgba(11,81, 83, 0.9);
			}
			&.green {
				background-color: rgba(22, 92, 38, 0.9);
			}
		}
	}
	.floating__block__img {
		@include inline-block;
		width: calc(100% - 525px);
		img {
			display: block;
			width: 100%;
		}
	}
	&.left {
		.floating__block__col {
			left:35px;
			.floating__block__col__title {
				padding-right: 100px;
				margin-right: -100px;
			}
		}
		.floating__block__img {
			float: right;
		}
	}
	&.right {
		.floating__block__col {
			right: 35px;
			.floating__block__col__title {
				padding-left: 100px;
				margin-left: -100px;
			}
		}
		.floating__block__img {
			float: left;
		}
	}
	@media screen and (max-width: 1780px) {
		.floating__block__col {
			top: 100px;
			.floating__block__col__title {
				padding: 25px 35px;
				margin-bottom: 10px;
			}
		}
		.floating__block__img {
			overflow: hidden;
			img {
				width: auto;
				height: 600px;
			}
		}
	}
	@media screen and (max-width: 1170px) {
		padding: 0px 0px;
		margin: 25px 0;
		.floating__block__img {
			width: 100%;
			position: relative;
			&:after {
				content: '';
				background-color: rgba(0,0,0,0.5);
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
			}
			img {
				width: 100%;
				height: auto;
			}
		}
		.floating__block__col {
			z-index: 1;
			.floating__block__col__title {
				margin: 0;		
			}
			.floating__block__col__info {
				color: #fff;
			}
		}
		&.left, &.right {
			.floating__block__col {
				width: auto;
				left: 15px;
				top: 15px;
				right: 15px;
				.floating__block__col__title {
					padding: 15px;
					margin: 0 0 15px 0;	
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.floating__block__col {
			position: relative;
			.floating__block__col__info {
				color: #2e2c2c;
			}
		}
		.floating__block__img {
			display: none;
			> img {
				height: 420px;
				width: auto;
			}
		}
		&.left, &.right {
			.floating__block__col {
				left: 0;
				right: 0;
				top: 0;
				padding-bottom: 20px;
			}
		}
	}
}
/* floating  block */

/* main about */
.about {
	background-color: #fff;
	padding-top: 65px;
	z-index: 1;
	.floating__block {
		padding-top: 15px;
		.floating__block__col {
			top: 72px;
			left: 50%;
			.floating__block__col__title {
				font-family: $bold;
				font-size: 20px;
				line-height: 1.2;
			}
			.btn {
				background-color: transparent!important;
				color: #299242!important;
				border: 2px solid #fff!important;
				padding: 15px 30px 15px 0px!important;
				position: relative;
				&:after {
					content: '\f054';
					display: block;
					font-family: 'fontAwesome';
					font-size: 16px;
					color: #299242;
					position: absolute;
					top: 15px;
					right: 5px;
					transition: 400ms;
				}
				&:hover {
					color: #00529f!important;
					&:after {
						right: 0;
						color: #00529f;
					}
				}
			}
		}
		.floating__block__img {
			width: 54.2%;
		}
		&.left {
			.floating__block__col {
				margin-left: -570px;
			}
		}
	}
	@media screen and (max-width: 1440px) {
		.floating__block {
			.floating__block__col {
				top: 45px;
			}
		}
	}
	@media screen and (max-width: 1170px) {
		.floating__block {
			.floating__block__img {
				display: block;
				width: auto;
				margin: 0 15px;
				float: none;
				img {
					width: 100%;
					height: auto;
				}
			}
			.floating__block__col {
				.floating__block__col__info {
					padding: 0 15px;
				}
				.btn {
					background-color: #fff!important;
					padding: 15px 30px 15px 15px!important;
				}
			}
			&.left {
				.floating__block__col {
					margin-left: 0;
					left: 15px;
					top: 15px;
					right: 15px;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		padding-top: 40px;
		.floating__block {
			padding-top: 0;
			margin-top: 0;
			.floating__block__col {
				left: 0;
				.btn {
					padding: 10px 30px 10px 20px!important;
					&:after {
						top: 10px;
					}
				}
			}
			.floating__block__img {
				display: none;
			}
			&.left {
				.floating__block__col {
					left: 0;
					.floating__block__col__title {
						margin: 0 15px 15px 15px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		padding-top: 35px;
	}
}
/* main about */

/* main activities */
.activities {
	background-color: #fff;
	.activities__col {
		display: block;
		text-decoration: none;
		.activities__col__img {
			overflow: hidden;
			position: relative;
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0	 ;
				background-color: rgba(41, 146, 66, 0.5);
				transition: 400ms;
				opacity: 0;
			}
			.btn {
				display: block;
				width: 160px!important;
				background-color: transparent!important;
				border: 2px solid #fff;
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				margin: -25px auto 0 auto;
				text-align: center;
				transition: 400ms;
				z-index: 1;
				opacity: 0;
				&:hover {
					color: #fff!important;
				}
			}
		}
		h4 {
			margin: 10px 0;
			text-transform: uppercase;
			transition: 400ms;
		}
		&:hover {
			.activities__col__img {
				&:after {
					opacity: 1;
				}
				.btn {
					opacity: 1;
				}
			}
			h4 {
				color: #299242;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.activities__col {
			margin-bottom: 25px;
			.activities__col__img {
				img {
					display: block;
					width: 100%;
				}
			}
			h4 {
				font-size: 18px;
				br {
					display: none;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.activities__col {
			.activities__col__img {
				&:after {
					opacity: 1;
				}
				.btn {
					opacity: 1;
				}
			}
			h4 {
				color: #299242;
			}
		}
	}
}
/* main activities */

/* main realize */
.realize {
	background-color: #000000;
	h1 {
		text-transform: inherit;
		color: #fff;
	}
	a {
		color: #fff;
		&:hover {
			color: #299242;
		}
	}
}
/* main realize */

/* main news */
.news {
	display: block;
	background-color: #fff;
	padding: 50px 0;
	h2 {
		margin: 0;
	}
	.news__block {
		position: relative;
		padding: 50px 0 0 0;
		overflow: hidden;
		.news__block__col {
			width: 400px;
			position: absolute;
			top: 25%;
			.news__block__col__title {
				display: block;
				font-family: $bold;
				font-size: 20px;
				line-height: 1.2;
				text-transform: uppercase;
				color: #00529f;
				padding-bottom: 30px;
				margin-bottom: 20px;
				position: relative;
				&:after {
					content: '';
					display: block;
					width: 120px;
					height: 2px;
					background-color: #00529f;
					position: absolute;
					bottom: 0;
					left: 0;
				}
			}
			.btn {
				display: block;
				width: 100%;
				height: 45px;
				padding: 13px 60px 13px 20px!important;
				box-sizing: border-box;
				text-align: left;
				position: relative;
				border: none;
				margin-top: 40px;
				&:before {
					content: '';
					display: block;
					width: 40px;
					background-color: #145624;
					position: absolute;
					top: 0px;
					right: 0px;
					bottom: 0px;
					transition: 300ms;
				}
				&:after {
					content: '\f105';
					font-family: 'fontAwesome';
					font-size: 18px;
					color: #fff;
					position: absolute;
					top: 13px;
    				right: 15px;
    				transform: rotate(0deg);
				}
				span {
					display: block;
					position: relative;
					z-index: 1;	
				}
				&:hover {
					background-color: #299242!important;
					color: #fff!important;
				}
			}
			
		}
		.news__block__img {
			display: inherit;
			width: 57%;
			position: relative;
			.news--date {
				display: block;
				position: absolute;
				top: 0;
				span {
					display: block;
					height: 28px;
					width: 100%;
					color: #fff;
					text-align: center;
					padding: 5px 10px;
					box-sizing: border-box;
					line-height: 1;
					&.day {
						background-color: #000;
						font-size: 16px;
					}
					&.year {
						background-color: #299242;
						font-size: 20px;
					}
				}
			}
			img {
				display: block;
				width: 100%;
			}
		}
		&.left {
			.news__block__col {
				left: 50%;
				padding-right: 25px;
				margin-left: -570px;
				.btn {
					margin-right: -25px;
					&:hover {
						&:before {
							width: 100%;
						}
					}
				}
			}
			.news__block__img {
				float: right;
				.news--date {
					left: 0;
				}
			}
		}
		&.right {
			.news__block__col {
				right: 50%;
				padding-left: 25px;
				margin-right: -570px;
				.btn {
					padding: 13px 20px 13px 60px!important;
					text-align: right;
					margin-left: -25px;
					&:before {
						content: '';
						top: 0px;
						right: auto;
						left: 0px;
						bottom: 0px;
						transition: 300ms;
						border: none;
					}
					&:after {
						content: '\f105';
	    				right: auto;
	    				left: 15px;
	    				transform: rotate(180deg);
					}
					&:hover {
						&:before {
							width: 100%;
						}
					}
				}
			}
			.news__block__img {
				float: left;
				.news--date {
					right: 0;
				}
			}
		}
	}
	@media screen and (max-width: 1440px) {
		.news__block {
			.news__block__img {
				width: 60%;
			}
			&.right {
				.news__block__col {
					margin-right: 15px;
					right: 0;
				}
			}
			&.left .news__block__col {
				margin-left: 0;
				left: 15px;
			}
		}
	}
	@media screen and (max-width: 1170px) {
		padding: 25px 0;
		.news__block {
			padding: 0;
			margin: 25px 0;
			.news__block__img {
				display: block;
				width: 100%;
				position: relative;
				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: rgba(0,0,0,0.5);	
				}
			}
			.news__block__col {
				color: #fff;
				z-index: 1;
				.news__block__col__title {
					color: #fff;
					padding-bottom: 15px;
					margin-bottom: 10px;
					&:after {
						background-color: #fff;
					}
				}
			}
			&.left, &.right {
				.news__block__col {
					width: auto;
					max-width: 400px;
					left: 15px;
					top: 25%;
					right: auto;
					padding: 0;
					margin: 0;
					.btn {
						text-align: left;
						padding: 13px 60px 13px 20px!important;
						margin: 0;
						&:before {
							left: auto;
							right: 0;
						}
						&:after {
							left: auto;
							right: 15px;
							transform: rotate(0deg);
						}
					}
				}
				.news__block__img {
					.news--date {
						left: auto;
						right: 0;
						z-index: 1;
					}
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		padding: 15px 0;
		.news__block {
			.news__block__col {
				.btn {
					height: auto;
					&:after {
						top: 8px;
					}
				}
			}
			&.left, &.right {
				.news__block__col {
					top: 10%;
					.btn {
						padding: 10px 60px 10px 20px!important;
					}
				}
			} 
		}
	}
	@media screen and (max-width: 580px) {
		.news__block {
			.news__block__col {
				.news__block__col__title {
					padding-bottom: 5px;
				}
			}
			.news__block__img {
				img {
					height: 300px;
					width: auto;
				}
			}
			&.left, &.right {
				.news__block__col {
					width: calc(100% - 100px);
					top: 15px;
					.news__block__col__title {
						font-size: 16px;
					}
				}
			}
		}
	}
}
/* main news */


/* main content */