/* page content */
.page {
	font-size: 18px;
	padding-top: 70px;
	padding-bottom: 70px;
	.news1, .news {
		.date {
			color: #299242;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: #299242;
				text-decoration: none;
			}
		}
	}
	.page_nav {
		.active {
			color: #299242;
		}
	}
	a {
		color: #282727;
		text-decoration: underline;
		transition: 300ms;
		&:active, &:focus {
			text-decoration: none;
		}
		&:hover {
			color: #299242;
			text-decoration: none;
		}
	}
	h4 {
		font-size: 16px;
	}
	.floating__block .floating__block__col .floating__block__col__info {
		font-size: 16px;
		p {
			font-size: 16px;
		}
	}
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		padding-bottom: 25px;
		padding-top: 35px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
	
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 16px;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #3361eb;
			@include inline-block;
			a {
				text-decoration: none;
			}
			&:after {
				content: '/';
				margin: 0px 2px 0px 7px;
				color: #282727;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
}
/* breadcrumbs */

/* page about */
.container--about {
	width: 100%;
	padding: 0 70px;
	margin-top: -70px;
	@media screen and (max-width: 1170px) {
		padding: 0 15px;
		margin-top: -35px;
	}
}
/* page about */

/* page contacts */
.contacts--address {
	position: relative;
	font-size: 16px;
	padding: 85px 100px 85px 50px;
	background-color: #1963a8;
	box-sizing: border-box;
	color: #fff;
	margin-bottom: 50px;
	&:after {
		content: '';
		display: block;
		position: absolute;
		width: 70px;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: #00529f;
	}
	ul {
		@include ul-default;
		li {
			display: block;
			margin: 8px 0px;
			a {
				font-size: 16px;
				color: #fff;
				text-decoration: none;
				&:hover {
					color: #fff;
					text-decoration: underline;
				}
			}
			span {
				font-family: $bold;
				text-transform: uppercase;
				&.contacts--address__left {
					@include inline-block;
				}
				&.contacts--address__right {
					width: calc(100% - 70px);
					font-family: $regular;
					text-transform: inherit;
					@include inline-block;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		padding: 25px 50px 25px 25px;
		margin-bottom: 15px;
		&:after {
			width: 50px;
		}
	}
	@media screen and (max-width: 768px) {
		padding: 20px 15px 20px 15px;
		&:after {
			display: none;
		}
	}
}
.map {
	height: 250px;
	overflow: hidden;
	@media screen and (max-width: 991px) {
		> ymaps {
			height: 250px!important;
		}
	}

}

.contacts--form {
	display: block;
	h3 {
		margin: 0 0 20px;
	}
	span {
		display: block;
		color: #3b3a3a;
		margin-bottom: 2px;
	}
	input[type="text"],
	input[type="email"],
	input[type="password"],
	textarea {
		display: block;
		width: 100%;
		padding: 10px 15px;
		margin-bottom: 10px;
		border: 1px solid #d1d1d1;
		&.input--small {
			width: 300px;
		}
	}
	.btn {
		transition: 300ms;
		float: right;
		margin-top: 15px;
	}
	@media screen and (max-width: 991px) {
		margin-top: 15px;
	}
	@media screen and (max-width: 768px) {
		input[type="text"],
		input[type="email"],
		input[type="password"],
		textarea {
			padding: 10px 15px;
			&.input--small {
				width: 100%;
				padding: 5px 15px;
			}
		}
		span {
			margin-bottom: 5px;
		}
	}
}
/* page contacts */

/* modal */
.modal {
	.modal-content {
		border-radius: 0px;
		h3 {
			margin: 0;
		}
		.close {
			position: absolute;
			width: 25px;
			height: 25px;
			font-family: 'FontAwesome';
			font-size: 25px;
			opacity: 1;
			right: 10px;
			top: 10px;
			outline: none;
			&:after {
				content: '\f00d';
			}
			&:hover {
				opacity: 0.7;
			}
		}
		.btn {
			background-color: #ff0000;
			width: 100%!important;
		}
	}
	@media screen and (max-width: 991px) {
		.modal-content h3 {
			font-size: 22px;
		}
	}
	@media screen and (max-width: 768px) {
		.modal-content h3 {
			font-size: 15px;
		}
	}
}
/* modal */

/* page content */