/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 13, 2018 */
@font-face {
    font-family: 'open_sansitalic';
    src: url('../fonts/opensans-italic-webfont.eot');
    src: url('../fonts/opensans-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-italic-webfont.woff2') format('woff2'),
         url('../fonts/opensans-italic-webfont.woff') format('woff'),
         url('../fonts/opensans-italic-webfont.ttf') format('truetype'),
         url('../fonts/opensans-italic-webfont.svg#open_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sanslight';
    src: url('../fonts/opensans-light-webfont.eot');
    src: url('../fonts/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-light-webfont.woff2') format('woff2'),
         url('../fonts/opensans-light-webfont.woff') format('woff'),
         url('../fonts/opensans-light-webfont.ttf') format('truetype'),
         url('../fonts/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/opensans-regular-webfont.eot');
    src: url('../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/opensans-regular-webfont.woff') format('woff'),
         url('../fonts/opensans-regular-webfont.ttf') format('truetype'),
         url('../fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sansbold';
    src: url('../fonts/open-sans-bold-webfont.eot');
    src: url('../fonts/open-sans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open-sans-bold-webfont.woff2') format('woff2'),
         url('../fonts/open-sans-bold-webfont.woff') format('woff'),
         url('../fonts/open-sans-bold-webfont.ttf') format('truetype'),
         url('../fonts/open-sans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

$regular: 'open_sansregular';
$bold: 'open_sansbold';
$italic: 'open_sansitalic';
$light: 'open_sanslight';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:after, *:before {
	display: block;
	line-height: 1;
}
body {
	margin: 0;
	font-family: $regular;
	color: #2e2c2c;
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: column;
	@media screen and (max-width: 1024px) {
		padding-top: 85px;
	}
}

h1 {
	font-size: 40px;
	font-family: $regular;
	text-transform: uppercase;
	color: #3b3a3a;
	display: block;
	margin-bottom: 25px;
	line-height: 1;
	text-align: center;
	@media screen and (max-width: 991px) {
		font-size: 32px;
	}
	@media screen and (max-width: 768px) {
		font-size: 28px;
	}
}
h2 {
	font-size: 36px;
	font-family: $bold;
	text-transform: uppercase;
	color: #3b3a3a;
	display: block;
	margin-top: 0px;
	margin-bottom: 40px;
	text-align: center;
	a {
		display: table;
		font-size: 36px!important;
		text-transform: uppercase;
		font-family: $bold;
		color: #3b3a3a;
		margin: 0 auto;
	}
	@media screen and (max-width: 991px) {
		font-size: 30px;
		margin-bottom: 20px;
		a {
			font-size: 30px!important;
		}
	}
	@media screen and (max-width: 768px) {
		font-size: 24px;
		a {
			font-size: 24px!important;
		}
	}
}
h3 {
	font-size: 24px;
	font-family: $bold;
	color: #3b3a3a;
	text-transform: uppercase;
	@media screen and (max-width: 991px) {
		font-size: 22px;
	}
	@media screen and (max-width: 768px) {
		font-size: 20px;
	}
}
h4 {
	font-size: 20px;
	font-family: $bold;
	color: #3b3a3a;
	text-transform: uppercase;
}
h5 {
	font-size: 18px;
	color: #3b3a3a;
	font-family: $bold;
	text-transform: uppercase;
}
h6 {
	font-size: 16px;
	text-transform: uppercase;
	color: #3b3a3a;
	font-family: $bold;
}
a {
	color: #3b3a3a;
	transition: 350ms;
	&:focus {
		color: #3b3a3a;
		text-decoration: none;
	}
	&:hover {
		color: #299242;
	}
}
input, textarea, button {
	box-sizing: border-box;
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	background-color: #fff;
	textarea, input {
		font-family: $regular;
		font-size: 16px;
		background-color: #fff;
		padding: 5px 15px;
	}
	a,p {
		font-size: 18px;
		text-decoration: none;
	}
	@media screen and (max-width: 991px) {
		a, p {
			font-size: 16px;
		}
	}
}
.main {
	.container {
		padding-top: 50px;
		padding-bottom: 50px;
		@media screen and (max-width: 1170px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}
.btn {
	display: table;
	padding: 15px 30px!important;
	font-size: 16px;
	font-family: $bold!important;
	color: #fff;
	text-transform: uppercase;
	background-color: #299242!important;
	cursor: pointer;
	width: auto!important;
	border-radius: 0px;
	border: 2px solid #299242;
	box-sizing: border-box;
	line-height: 1;
	&:focus, &:active {
		color: #fff;
		background-color: #299242!important;
		box-shadow: none;
	}
	&:hover, &.active {
		background-color: #fff!important;
		color: #299242!important;
	}	
	@media screen and (max-width: 768px) {
		font-size: 14px!important;
		padding: 12px 15px!important;
	}
}

.check--list {
	@include ul-default;
	li {
		display: block;
		padding-left: 25px;
		position: relative;
		margin-bottom: 15px;
		font-size: 18px;
		&:before {
			content: '\f14a';
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #299242;
			position: absolute;
			left: 0;
			top: 3px;
		}
		a {
			&:hover {
				text-decoration: underline;
			}
		}
	}
	@media screen and (max-width: 991px) {
		li {
			font-size: 16px;
		}
	}
}

/* header */
header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	transition: 300ms;
	z-index: 9;
	ul {
		@include ul-default;
	}
	a {
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}

	/* header logo */
	.logo {
		width: 147px;
		margin: 20px 0px 0px 65px;
		transition: 300ms;
		@include inline-block;
		img {
			display: block; 	
			width: 100%;
		}
	}
	/* header logo */

	/* header nav */
	.nav {
		margin-left: 135px;
		font-size: 0;
		@include inline-block;
		> ul > li {
			position: relative;
			@include inline-block;
			&:after {
				content: '';
				width: 2px;
				height: 18px;
				background-color: #d6d6d6;
				position: absolute;
				top: 55px;
				right: -1px;
				transition: 300ms;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
			> a {
				display: block;
				font-family: $bold;
				font-size: 18px;
				text-transform: uppercase;
				color: #fff;
				padding: 55px 20px;
				line-height: 1;
				&:hover {
					text-decoration: none;
					color: #299242!important;
				}
			}
			&.active > a {
				color: #299242!important;
			}
			&.submenu {
				ul {
					display: none;
					width: 465px;
					position: absolute;
					top: 128px;
					left: 0;
					padding: 53px 0px 53px 72px;
					box-sizing: border-box;
					border-top: 10px solid #29903f;
					background-color: #000000;
					z-index: 1;
					&:before {
						content: '';
						display: block;
						width: 1px;
						position: absolute;
						top: 46px;
						bottom: 46px;
						left: 46px;
						background-color: #299144;
					}
					&:after {
						content: '';
						display: block;
						width: 0;
						height: 0;
						border-bottom: 17px solid #29903f;
						border-right: 16px solid transparent;
						border-left: 16px solid transparent;
						position: absolute;
						left: 40px;
						top: -27px;
					}
					li {
						display: block;
						line-height: 1;
						margin:0;
						position: relative;
						a {
							display: block;
							font-size: 16px;
							text-transform: uppercase;
							color: #fff;
							padding: 8px 0 8px 0;
							&:hover {
								color: #29903f;
								text-decoration: none;
							}
						}
						&.active > a {
							color: #29903f;
						}
						&.submenu__sub {
							ul {
								top: -60px;
								left: 393px;
								&:after {
									display: none;
								}
							}
						}
					}
				}
			}
		} 
	}
	/* header nav */

	/* header langs */
	.langs {
		margin: 54px 0px 0px 20px;
		transition: 300ms;
		@include inline-block;
		li {
			position: relative;
			line-height: 1;
			@include inline-block;
			&:after {
				content: '/';
				font-size: 16px;
				color: #fff;
				margin: 1px 8px 0px 10px;	
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
			a {
				color: #fff;
				font-size: 15px;
				&:hover {
					color: #299242!important;
				}
			}
			&.active a {
				color: #299242!important;
				text-decoration: underline;
			}
		}
	}
	/* header langs */

	/* header search */
	.search {
		width: 265px;
		height: 44px;
		position: relative;
		background-color: #fff;
		box-sizing: border-box;
		padding-right: 45px;
		float: right;
		margin: 43px 65px 0px 0px;
		transition: 300ms;
		border: 1px solid #fff;
		@include inline-block;
		input[type="text"] {
			width: 100%;
			height: 100%;
			border: none;
			outline: none;
			padding-left: 15px;
		}
		.search__btn {
			display: block;
			width: 17px;
			height: 17px;
			border: none;
			outline: none;
			font-family: 'fontAwesome';
			font-size: 17px;
			background-color: transparent;
			padding: 0;
			color: #299242;
			position: absolute;
			top: 12px;
			right: 20px;
			&:after {
				content: '\f002';
			}
		}
	}
	.search--btn, .nav--btn {
		display: none;
	}
	/* header search */

	/* header phones */
	.phones {
		position: absolute;
		right: 375px;
		top: 39px;
		padding-left: 25px;
		transition: 300ms;
		&:after {
			content: '\f095';
			font-family: 'fontAwesome';
			font-size: 20px;
			color: #299242;
			position: absolute;
			top: 3px;
			left: 0;
		}
		ul {
			li {
				display: block;
				a {
					font-size: 18px;
					color: #fff;
					&:hover {
						color: #299242;
					}
				}
			}
		}
	}
	/* header phones */
	
	&.header {
		position: fixed;
		background-color: #fff;
		padding-top: 0;
		.nav {
			> ul > li {
				&:after {
					top: 34px;
					opacity: 0;
				}
				> a {
					padding: 34px 15px;
					color: #3b3a3a;
					font-size: 16px;
				}
				&.submenu {
					ul {
						top: 84px;
					}
				}
			}
		}
		.logo {
			width: 100px;
			margin-top: 10px;
		}
		.langs {
			margin-top: 34px;
			ul li {
				&:after {
					color: #3b3a3a;
				}
				a {
					color: #3b3a3a;
				}
			}
		}
		.search {
			width: 265px;
			border: 1px solid #cecccc;
			margin-top: 20px;
		}
		.phones {
			top: 18px;
			ul li a {
				color: #3b3a3a;
			}
		}
	}
	@media screen and (max-width: 1780px) {
		.nav {
			margin-left: 70px;
		}
	}
	@media screen and (max-width: 1680px) {
		.logo {
			margin-left: 30px;
		}

		.search {
			width: 220px;
			margin-right: 30px;
		}

		.nav {
			margin-left: 20px;
		}
		.phones {
			right: 290px;
		}
		&.header {
			.search {
				width: 220px;
			}
		}
	}
	@media screen and (max-width: 1580px) {
		.logo {
			margin-left: 15px;
		}

		.search {
			width: 200px;
			margin-right: 15px;
		}

		.nav {
			margin-left: 10px;
			> ul > li {
				> a {
					font-size: 16px;
					padding: 55px 15px;
				}
				&.submenu > ul {
					&:after {
						left: 30px;
					}
				}
			}
		}

		.phones {
			right: 255px;
		}

		&.header {
			.search {
				width: 220px;
			}
		}
	}
	@media screen and (max-width: 1440px) {
		.nav > ul > li {
			> a {
				padding: 55px 10px;
			} 
			&.submenu {
				ul {
					padding: 20px 0 20px 40px;
					&:before {
						left: 20px;
						top: 30px;
						bottom: 30px;
					}
					li {
						a {
							font-size: 14px;
						}
						&.submenu__sub {
							ul {
								left: 100%;
								top: -23px;
							}
						}
					}
				}
			}
		}

		.search {
			width: 200px;
			padding-right: 30px;
			.search__btn {
				right: 7px;
			}
		}
	}
	@media screen and (max-width: 1366px) {
		.nav > ul > li {
			> a {
				font-size: 16px;
				padding: 30px 15px;
			}
			&:after {
				top: 29px;
			}
			&.submenu {
				ul {
					top: 76px;
					li {
						a {
							padding: 5px 0;
						}
					}
				}
			}
		}
		.langs {
			margin-top: 30px;
		}
		.search {
			margin-top: 17px;
		}
		.logo {
			width: 120px;
		}
		.phones {
			display: none;
			background-color: #299242;
			padding: 15px 20px;
			top: 17px;
			right: 234px;
			z-index: 1;
			transition: 0ms;
			&:after {
				display: none;
			}
			ul li {
				a {
					&:hover {
						color: #fff;
					}
				}
			}
		}
		.phones--btn {
			color: #299242;
			text-decoration: none;
			font-family: 'fontAwesome';
			font-size: 24px;
			position: absolute;
			right: 240px;
			top: 27px;
			&:after {
				content: '\f095';
			}
			&:hover {
				text-decoration: none;
			}
		}
		&.header {
			.search {
				width: 200px;
			}
			.phones--btn {
				font-size: 22px;
				top: 30px;
			}
			.phones ul li a {
				color: #fff;
			}
		}
	}
	@media screen and (max-width: 1280px) {
		.nav > ul > li {
			&:after {
				top: 34px;
			}
			> a {
				padding: 35px 10px;
			}
		}
	}
	@media screen and (max-width: 1200px) {
		.search {
			width: 265px!important;
			border: 1px solid #cecccc;
			position: absolute;
			top: 20px!important;
			right: 10px;
			margin: 0!important;
		}
		.search {
			display: none;
			width: 100%!important;
			left: 0;
			right: 0;
			z-index: 1;
			transition: 0ms;
		}
		.search--btn {
			display: block;
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 22px;
			color: #299242;
			position: absolute;
		    right: 14px;
    		top: 28px;
			&:after {
				content: '\f002';
			}
		}
		.phones {
			top: 15px!important;
			right: 15px;
			padding: 10px 15px;
			ul li {
				a {
					font-size: 16px;
				}
			}
		}
		.phones--btn {
			right: 50px;
		}
	}
	@media screen and (max-width: 1024px) {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		background-color: #fff;
		border-bottom: 2px solid #29903f;

		.nav {
			display: none;
			position: fixed;
			margin: 0;
			top: 85px;
			left: 0;
			right: 0;
			max-height: calc(100% - 85px);
			overflow: auto;
			background-color: #fff; 
			> ul > li {
				display: block;
				&:after {
					top: 34px;
					opacity: 0;
				}
				> a {
					padding: 10px 15px!important;
					color: #3b3a3a;
					font-size: 16px;
				}
				&.submenu {
					ul {
						width: 100%;
						top: 0!important;
						position: relative;
						left: 0;
						right: 0;
						padding: 10px 15px;
						border-top: 2px solid #29903f;
						border-bottom: 3px solid #29903f;
						&:after, &:before {
							display: none;
						}
						li {
							margin: 10px 0;
							&:after {
								display: none;	
							}
							&.submenu__sub {
								ul {
									padding: 5px 10px;
									margin: 10px 0;
									top: 0!important;
									left: 0!important;
								}
							}
						}
					}
				}
			}
		}
		.nav--btn {
			display: block;
			width: 35px;
			height: 30px;
			position: absolute;
			left: 10px;
			top: 27px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #299242;
				transition: 300ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}

		.logo {
			display: block;
			width: 100px!important;
			margin: 10px auto 0;
		}

		.langs {
			margin: 0!important;
			position: absolute;
			top: 34px;
			left: 60px;
			ul li {
				&:after {
					color: #3b3a3a;
					margin: 0px 5px 0 8px;
				}
				a {
					color: #3b3a3a;
				}
			}
		}
		.phones--btn {
			font-size: 22px!important;
			top: 29px!important;
		}
		.phones {
			top: 10px!important;
			right: 42px;
		}
	}
	@media screen and (max-width: 768px) {
		.langs {
			left: 52px;
			li:after {
				margin: 0px 2px 0px 4px!important;
			}
		}
	}
	@media screen and (max-width: 420px) {
		.phones {
			width: 100%;
			position: fixed;
			top: 0!important;
			left: 0;
			right: 0;
			text-align: center;
		}
	}
}
/* header */